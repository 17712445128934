import Box from "@mui/material/Box";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import React from "react";
import Typography from "@mui/material/Typography";
import { Avatar, Chip, Icon, IconButton, Paper } from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { ArrowCircleRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export const CustomCard = ({ path, naziv, icon, bgcolor }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(path);
  };

  return (
    <React.Fragment>
      <Paper
        onClick={handleNavigate}
        variant="outlined"
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          overflow: "hidden",
          borderRadius: 2,
          py: 1.5,
          px: 2,
        }}
      >
        <Avatar
          sx={{
            borderRadius: 2,
            backgroundColor: bgcolor,
            mr: 2,
          }}
        >
          <Icon>{icon}</Icon>
        </Avatar>
        <Typography noWrap variant="h6" fontWeight="bold">
          {naziv}
        </Typography>
        {/*<Box flexGrow={1} />
        <Box>
          <Chip
            variant="filled"
            color="primary"
            label={st}
            size="small"
            sx={{ ml: 1 }}
          />
        </Box>
        <IconButton color="primary" onClick={handleNavigate}>
          <ArrowCircleRight />
        </IconButton>*/}
      </Paper>
    </React.Fragment>
  );
};
