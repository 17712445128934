import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Icon,
  Menu,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { addHranjenje } from 'store/slices/hranjenjaSlice';
const schema = yup.object().shape({
  id_panj: yup.number().required('Vnesite panj.'),
  id_hrana: yup.number().required('Vnesite hrano.'),
  kolicina: yup.number().required('Vnesite količino.'),
});

export default function Hranjenje(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const panji = useSelector((state) => state.panji.panji);
  const hrana = useSelector((state) => state.hranjenja.hrana);
  console.log(panji);

  const methods = useForm({
    mode: 'onChange',
    defaultValue: {},
    resolver: yupResolver(schema),
  });

  const { reset, watch, control, onChange, formState, handleSubmit } = methods;
  const onSubmit = (data) => {
    //data.kolicina = parseInt(data.kolicina);
    console.log(data);
    dispatch(addHranjenje(data)).then(() => {
      //navigate('/hranjenja');
    });
  };
  const form = watch();
  return (
    <div>
      <Box textAlign="center" mb={4}>
        <Icon onClick={() => navigate(-1)}>arrow_back_rounded</Icon>
        <Typography variant="h5" fontWeight="bold">
          Novo hranjenje
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1.5}>
          <Controller
            name="id_panj"
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  sx={{ backgroundColor: 'background.paper' }}
                  {...field}
                  required
                  fullWidth
                  select
                  id="id_panj"
                  label="Panj"
                  type="number"
                  variant="outlined"
                >
                  {_.map(panji, (item) => {
                    return (
                      <MenuItem key={item.id_panj} value={item.id_panj}>
                        {item.id_panj}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </>
            )}
          />

          <Controller
            name="id_hrana"
            control={control}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <TextField
                  sx={{ backgroundColor: 'background.paper' }}
                  {...field}
                  required
                  fullWidth
                  select
                  id="id_hrana"
                  label="Hrana"
                  type="number"
                  variant="outlined"
                >
                  {_.map(hrana, (item) => {
                    return (
                      <MenuItem key={item.hrana} value={item.id_hrana}>
                        {item.hrana}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </>
            )}
          />

          <Controller
            name="kolicina"
            control={control}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <TextField
                  sx={{ backgroundColor: 'background.paper' }}
                  {...field}
                  required
                  fullWidth
                  id="kolicina"
                  label="Količina"
                  type="number"
                  variant="outlined"
                />
              </>
            )}
          />
        </Stack>
        <Button
          sx={{ borderRadius: 8, my: 3, p: 2 }}
          size="large"
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          onClick={() => navigate(-1)}
        >
          Vnesi hranjenje
        </Button>
      </form>
    </div>
  );
}
