import axios from 'axios';

const customAxios = axios.create({
  baseURL: 'http://api.cebelarstvo-pezdevsek.si/api/v1',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': '*',
  },
});

export default customAxios;
