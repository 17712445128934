import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import customAxios from '../../axios';
import _ from 'lodash';

export const getPanji = createAsyncThunk('panji/getPanji', async () => {
  const response = await customAxios.get('/panji');
  const data = await response.data;
  console.log('Panji', data);
  return data;
});

export const getPanjiVrste = createAsyncThunk('panji/getVrste', async () => {
  const response = await customAxios.get('/panji/vrste');
  const data = await response.data;
  console.log('Vrste', data);
  return data;
});

export const getBarve = createAsyncThunk('panji/getBarve', async () => {
  const response = await customAxios.get('/panji/barve');
  const data = await response.data;
  return data;
});

export const addPanj = createAsyncThunk('panji/addPanj', async (panj) => {
  const response = await customAxios.post('/panji/addPanj', panj);
  const data = await response.data;
  console.log('Panji', data);
  return data;
});

const panjSlice = createSlice({
  name: 'panji',
  initialState: {
    panji: [],
    vrste: [],
    barve: [],
  },
  reducers: {},
  extraReducers: {
    [getPanji.fulfilled]: (state, action) => {
      state.panji = action.payload;
    },
    [getPanjiVrste.fulfilled]: (state, action) => {
      state.vrste = action.payload;
    },
    [getBarve.fulfilled]: (state, action) => {
      state.barve = action.payload;
    },
    [addPanj.fulfilled]: (state, action) => {
      state.panji = [...state.panji, action.payload];
    },
  },
});

export default panjSlice.reducer;
