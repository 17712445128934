import { refresh } from 'store/slices/authSlice';
import customAxios from '../axios';

const setupInterceptors = (store) => {
  customAxios.interceptors.request.use(
    (config) => {
      const { auth } = store.getState();
      if (!config.headers['Authorization']) {
        config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  const { dispatch } = store;
  customAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
      console.log('test');
      const prevRequest = error?.config;

      if (error?.response?.status === 403 && !prevRequest?.sent) {
        prevRequest.sent = true;
        const newAccessToken = await dispatch(refresh());
        prevRequest.headers['Authorization'] = `Bearer ${newAccessToken.accessToken}`;
        return customAxios(prevRequest);
      }

      localStorage.setItem('persist', false);

      return Promise.reject(error);
    }
  );

  /*customAxios.interceptors.request.eject(requestInterceptor);
  customAxios.interceptors.response.eject(responseInterceptor);*/
};
export default setupInterceptors;
