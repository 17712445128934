import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Chip,
  Icon,
  MenuItem,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { addPregled } from 'store/slices/preglediSlice';
import _ from 'lodash';
const schema = yup.object().shape({
  id_panj: yup.number().required('Vnesite potrebne podatke'),
});

export default function Pregled(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const pregledi = useSelector((state) => state.pregledi);
  const panji = useSelector((state) => state.panji.panji);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit } = methods;

  function onSubmit(data) {
    dispatch(addPregled(data));
  }

  return (
    <div>
      <Box textAlign="center" mb={4}>
        <Icon onClick={() => navigate(-1)}>arrow_back_rounded</Icon>
        <Typography variant="h5" fontWeight="bold">
          Nov pregled
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          Kaj vidite v panju?
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1.5}>
          <Controller
            name="id_panj"
            control={control}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <TextField
                  sx={{ backgroundColor: 'background.paper' }}
                  {...field}
                  required
                  fullWidth
                  select
                  id="id_panj"
                  label="Panj"
                  type="number"
                  variant="outlined"
                >
                  {_.map(panji, (item) => (
                    <MenuItem type="number" key={item.id_panj + item.id_vrste} value={item.id_panj}>
                      {item.id_panj}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            )}
          />

          <Controller
            name="mirnost"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <ToggleButton
                  sx={{ borderRadius: 6, backgroundColor: 'background.paper' }}
                  fullWidth
                  value="mirnost"
                  selected={field.value}
                  onChange={() => {
                    field.onChange(!field.value);
                  }}
                >
                  Mirnost
                </ToggleButton>
              </>
            )}
          />
          <Controller
            name="moc"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <ToggleButton
                  sx={{ borderRadius: 6, backgroundColor: 'background.paper' }}
                  fullWidth
                  value="moc"
                  selected={field.value}
                  onChange={() => {
                    field.onChange(!field.value);
                  }}
                >
                  Moč
                </ToggleButton>
              </>
            )}
          />
          <Controller
            name="matica"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <ToggleButton
                  sx={{ borderRadius: 6, backgroundColor: 'background.paper' }}
                  fullWidth
                  value="matica"
                  selected={field.value}
                  onChange={() => {
                    field.onChange(!field.value);
                  }}
                >
                  Matica
                </ToggleButton>
              </>
            )}
          />

          <Controller
            name="maticniki"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <ToggleButton
                  sx={{ borderRadius: 6, backgroundColor: 'background.paper' }}
                  fullWidth
                  value="maticniki"
                  selected={field.value}
                  onChange={() => {
                    field.onChange(!field.value);
                  }}
                >
                  Maticniki
                </ToggleButton>
              </>
            )}
          />

          <Controller
            name="jajceca"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <ToggleButton
                  sx={{ borderRadius: 6, backgroundColor: 'background.paper' }}
                  fullWidth
                  value="jajceca"
                  selected={field.value}
                  onChange={() => {
                    field.onChange(!field.value);
                  }}
                >
                  Jajčeca
                </ToggleButton>
              </>
            )}
          />

          <Controller
            name="zerke"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <ToggleButton
                  sx={{ borderRadius: 6, backgroundColor: 'background.paper' }}
                  fullWidth
                  value="zerke"
                  selected={field.value}
                  onChange={() => {
                    field.onChange(!field.value);
                  }}
                >
                  Žerke
                </ToggleButton>
              </>
            )}
          />
          <Controller
            name="pokrita_zalega"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <ToggleButton
                  sx={{ borderRadius: 6, backgroundColor: 'background.paper' }}
                  fullWidth
                  value="pokrita_zalega"
                  selected={field.value}
                  onChange={() => {
                    field.onChange(!field.value);
                  }}
                >
                  Pokrita zalega
                </ToggleButton>
              </>
            )}
          />

          <Controller
            name="zaloga_hrane"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <ToggleButton
                  sx={{ borderRadius: 6, backgroundColor: 'background.paper' }}
                  fullWidth
                  value="zaloga_hrane"
                  selected={field.value}
                  onChange={() => {
                    field.onChange(!field.value);
                  }}
                >
                  Zaloga hrane
                </ToggleButton>
              </>
            )}
          />

          <Controller
            name="prevesanje"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <ToggleButton
                  sx={{ borderRadius: 6, backgroundColor: 'background.paper' }}
                  fullWidth
                  value="prevesanje"
                  selected={field.value}
                  onChange={() => {
                    field.onChange(!field.value);
                  }}
                >
                  Prevešanje
                </ToggleButton>
              </>
            )}
          />

          <Controller
            name="izrez_trotovine"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <ToggleButton
                  sx={{ borderRadius: 6, backgroundColor: 'background.paper' }}
                  fullWidth
                  value="izrez_trotovine"
                  selected={field.value}
                  onChange={() => {
                    field.onChange(!field.value);
                  }}
                >
                  Izrez trotovine
                </ToggleButton>
              </>
            )}
          />

          <Controller
            name="poapnela_zalega"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <ToggleButton
                  sx={{ borderRadius: 6, backgroundColor: 'background.paper' }}
                  fullWidth
                  value="poapnela_zalega"
                  selected={field.value}
                  onChange={() => {
                    field.onChange(!field.value);
                  }}
                >
                  Poapnela zalega
                </ToggleButton>
              </>
            )}
          />

          <Controller
            name="varoja"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <ToggleButton
                  sx={{
                    borderRadius: 6,
                    backgroundColor: 'background.paper',
                  }}
                  fullWidth
                  value="varoja"
                  selected={field.value}
                  onChange={() => {
                    field.onChange(!field.value);
                  }}
                >
                  Varoja
                </ToggleButton>
              </>
            )}
          />
          <Controller
            name="ugotovitve"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <TextField
                  sx={{ backgroundColor: 'background.paper' }}
                  {...field}
                  fullWidth
                  id="ugotovitve"
                  label="Ugotovitve"
                  type="text"
                  rows={4}
                  variant="outlined"
                />
              </>
            )}
          />
        </Stack>
        <Button
          sx={{ borderRadius: 8, my: 3, p: 2 }}
          size="large"
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => navigate(-1)}
          fullWidth
        >
          Vnesi pregled
        </Button>
      </form>
    </div>
  );
}
