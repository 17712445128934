export const drawerConfig = [
  {
    title: 'Predvidena opravila',
    path: '/opravila',
    icon: 'assignment_rounded',
    bgcolor: 'secondary.main',
  },
  {
    title: 'Panji',
    path: '/panji',
    icon: 'analytics_rounded',
    bgcolor: 'primary.main',
  },
  {
    title: 'Pregledi',
    path: '/pregledi',
    icon: 'search_rounded',
    bgcolor: '#ffcc00',
  },
  {
    title: 'Hranjenja',
    path: '/hranjenja',
    icon: 'emoji_nature_rounded',
    bgcolor: '#f7a708',
  },
  {
    title: 'Zdravljenja',
    path: '/zdravljenja',
    icon: 'local_hospital_rounded',
    bgcolor: '#ff1a1a',
  },
];
