import React from 'react';
import { Typography, Stack, Button } from '@mui/material';
import HranjenjaTable from './HranjenjaTable';
import { Link } from 'react-router-dom';

export default function Hranjenja() {
  return (
    <div>
      <Stack pb={2} direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontWeight="bold" variant="h5">
          Hranjenja
        </Typography>
        <Button
          component={Link}
          to="/hranjenja/new"
          variant="contained"
          sx={{ borderRadius: 8, textTransform: 'none' }}
        >
          Dodaj hranjenja
        </Button>
      </Stack>
      <HranjenjaTable />
    </div>
  );
}
