import Pregled from 'pages/pregledi/Pregled';
import Pregledi from '../pages/pregledi';
import Hranjenje from 'pages/hranjenja/Hranjenje';
import Hranjenja from '../pages/hranjenja';
import Zdravljenje from 'pages/zdravljenja/Zdravljenje';
import Zdravljenja from '../pages/zdravljenja';
import Dashboard from '../layout/dashboard';
import Login from '../pages/login';
import NotFound from '../pages/notfound';
import Register from '../pages/register';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import Cebelnjak from 'pages/cebelnjak';
import Opravila from 'pages/opravila';
import RequireAuth from 'components/RequireAuth';
import PersistLogin from 'components/PersistLogin';
import CebelnjakNew from 'pages/cebelnjak/CebelnjakNew';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Panji from 'pages/panj';
import Panj from 'pages/panj/Panj';

export default function Router() {
  const cebelnjaki = useSelector((state) => state.cebelnjak.data);
  console.log('Cebelnjaki', cebelnjaki);
  return useRoutes([
    {
      element: <PersistLogin />,
      children: [
        {
          path: '/',
          element: <Dashboard />,
          children: [
            { 
              path: '/', 
              element: _.isEmpty(cebelnjaki) ? <CebelnjakNew /> : <Cebelnjak /> 
            },
            { path: '/new', element: <CebelnjakNew /> },
            { path: '/panji', element: <Panji /> },
            { path: '/panji/new', element: <Panj /> },
            { path: 'pregledi', element: <Pregledi /> },
            { path: 'pregledi/new', element: <Pregled /> },
            { path: 'zdravljenja', element: <Zdravljenja /> },
            { path: 'zdravljenja/new', element: <Zdravljenje /> },
            { path: 'hranjenja', element: <Hranjenja /> },
            { path: 'hranjenja/new', element: <Hranjenje /> },
            { path: 'opravila', element: <Opravila /> },
          ],
        },
      ],
    },
    { path: 'login', element: <Login /> },
    { path: 'register', element: <Register /> },
    { path: '*', element: <NotFound /> },
  ]);
}
