import Login from 'pages/login';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { refresh } from 'store/slices/authSlice';

const PersistLogin = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const checkToken = document.cookie.indexOf('ct') == 0;
  console.log('auth', auth);

  React.useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        console.log('refreshing');
        await dispatch(refresh());
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    // persist added here AFTER tutorial video
    // Avoids unwanted call to verifyRefreshToken
    !auth?.accessToken && checkToken ? verifyRefreshToken() : setIsLoading(false);

    return () => (isMounted = false);
  }, []);

  React.useEffect(() => {
    console.log(`isLoading: ${isLoading}`);
    console.log(`aT: ${JSON.stringify(auth?.accessToken)}`);
  }, [isLoading]);

  return <>{!checkToken ? <Login /> : isLoading ? <p>Loading...</p> : <Outlet />}</>;
};

export default PersistLogin;
