import React from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import allReducers from './store';
import Router from 'configs/routesConfig';
import { BrowserRouter } from 'react-router-dom';
import GlobalThemeStyles from 'theme/globalStyles';
import { theme } from 'theme';
import { ThemeProvider } from '@mui/material';
import store from './store';
import setupInterceptors from 'axios/interceptors';

setupInterceptors(store);

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <GlobalThemeStyles />
          <Router />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
