import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import customAxios from '../../axios';

export const getUserCebelnjakInfo = createAsyncThunk(
  'cebelnjak/getUserCebelnjakInfo',
  async (id_cebelar) => {
    const response = await customAxios.get(`/cebelnjak/${id_cebelar}`);
    const data = await response.data;
    return data;
  }
);

export const addCebelnjak = createAsyncThunk('cebelnjak/addCebelnjak', async (cebelnjak) => {
  const { data, id_cebelar } = cebelnjak;
  const response = await customAxios.post('/cebelnjak/addCebelnjak', { data, id_cebelar });
  return data;
});

const cebelnjakSlice = createSlice({
  name: '/cebelnjak',
  initialState: {
    data: [],
    selected: {},
  },
  reducers: {
    setSelectedCebelnjak: (state, action) => {
      state.selected = action.payload;
    },
  },
  extraReducers: {
    [getUserCebelnjakInfo.fulfilled]: (state, action) => {
      state.data = action.payload;
      //console.log('data', state.data);
      //_.fill(state.data, action.payload);
    },
    [addCebelnjak.fulfilled]: (state, action) => {
      state.data = [...state.data, action.payload];
    },
  },
});

export const { setSelectedCebelnjak } = cebelnjakSlice.actions;

export default cebelnjakSlice.reducer;
