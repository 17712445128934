import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import customAxios from '../../axios';

export const getPregledi = createAsyncThunk('pregledi/getPregledi', async () => {
  const response = await customAxios.get('/pregledi');
  const data = await response.data;
  return data;
});

export const addPregled = createAsyncThunk('pregledi/addPregled', async (pregled) => {
  const response = await customAxios.post('/pregledi/addPregled', pregled);
  return await response.data;
});

const preglediSlice = createSlice({
  name: 'pregledi',
  initialState: [],
  reducers: {
    deletePregled: (state, action) => {
      console.log(action);
      state = state.filter((item) => action.payload !== item.id_pregled);
    },
  },
  extraReducers: {
    [getPregledi.fulfilled]: (state, action) => action.payload,
    [addPregled.fulfilled]: (state, action) => {
      state = [...state, action.payload];
    },
  },
});

export const { deletePregled } = preglediSlice.actions;

export default preglediSlice.reducer;
