import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { CustomCard } from './CustomCard';
import { Box, Typography } from '@mui/material';
import { drawerConfig } from 'configs/drawerConfig';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getHrana } from 'store/slices/hranjenjaSlice';
import { getBolezni } from 'store/slices/zdravljenjaSlice';
import { getUserCebelnjakInfo } from 'store/slices/cebelnjakSlice';
import Timer from 'components/Timer';

export default function Cebelnjak() {
  const { id_cebelar } = useSelector((state) => state.auth);
  const cebelnjak = useSelector((state) => state.cebelnjak.data);
  const dispatch = useDispatch();
  const [ime, setIme] = React.useState('');
  /*
  console.log('Date now', Date.now());
  console.log('Lodash now', _.now());*/

  /*var neki = performance.now();
  console.log('hello');
  console.log('hello');
  console.log('hello');
  console.log('hello');
  console.log('hello');
  console.log('hello');
  console.log('hello');
  console.log('hello');
  console.log('hello');
  var neki2 = performance.now();
  console.log('Time je: ' + (neki2 - neki));

  var neki3 = performance.now();
  console.log('hello');
  console.log('hello');
  console.log('hello');
  console.log('hello');
  console.log('hello');
  console.log('hello');
  console.log('hello');
  console.log('hello');
  console.log('hello');
  var neki4 = performance.now();
  console.log('Time2 je: ' + (neki4 - neki3));*/

  //var array = ['a', 'b', 'c', 'a', 'b', 'c'];

  //_.pull([30, 12, 54, 12, 9], 12);
  //Rezultat: [30, 54, 9]

  //let polje = ['', false, null, undefined, NaN, 3, 5];
  //_.compat(polje);
  //Rezultat: [3, 5]

  //var polje = new Array(4);
  //_.fill(polje, 0);
  //Rezultat: [0, 0, 0, 0]

  //let polje = [4, 3, 4, 1, 7];
  //_.remove(polje, function (element) {
  //   if (element === 4) return true;
  //});
  //Rezultat: [3, 1, 7]

  React.useEffect(() => {
    setIme(cebelnjak[0].ime);
  }, [cebelnjak]);

  return (
    <Box>
      <Box mb={2}>
        <Typography fontWeight="bold" variant="h5">
          Pozdravljen, Nejc!
        </Typography>
      </Box>
      {/*map actions*/}
      <Grid container spacing={1}>
        {_.map(drawerConfig, (item) => {
          return (
            <Grid item xs={12} sm={6} md={3}>
              <CustomCard
                path={item.path}
                naziv={item.title}
                icon={item.icon}
                bgcolor={item.bgcolor}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
