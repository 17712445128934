import React from "react";
import { Typography, Stack, Button } from "@mui/material";
import PreglediTable from "./PreglediTable";
import { Link } from "react-router-dom";
export default function Pregledi() {
  return (
    <div>
      <Stack
        pb={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography fontWeight="bold" variant="h5">
          Pregledi
        </Typography>
        <Button
          component={Link}
          to="/pregledi/new"
          variant="contained"
          sx={{ borderRadius: 8, textTransform: "none" }}
        >
          Dodaj pregled
        </Button>
      </Stack>
      <PreglediTable />
    </div>
  );
}
