import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    text: {
      list: '#ffffff',
    },
    primary: {
      main: '#1d2430',
      light: '#42a5f5',
      dark: '#1565c0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#9c27b0',
      light: '#ba68c8',
      dark: '#7b1fa2',
      contrastText: '#fff',
    },
  },
});
