import { configureStore } from '@reduxjs/toolkit';
import cebelnjakSlice from './slices/cebelnjakSlice';
import panjiSlice from './slices/panjSlice';
import hranjenjaSlice from './slices/hranjenjaSlice';
import preglediSlice from './slices/preglediSlice';
import zdravljenjaSlice from './slices/zdravljenjaSlice';
import authSlice from './slices/authSlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    cebelnjak: cebelnjakSlice,
    panji: panjiSlice,
    pregledi: preglediSlice,
    hranjenja: hranjenjaSlice,
    zdravljenja: zdravljenjaSlice,
  },
  devTools: false,
});

export default store;
