import {
  AppBar,
  Avatar,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/MenuRounded';
import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCebelnjak } from 'store/slices/cebelnjakSlice';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 275;

export default function DashboardNavbar({ onOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cebelnjak = useSelector((state) => state.cebelnjak);
  const handleChange = (event) => {
    console.log(event.target.value);
    dispatch(setSelectedCebelnjak(event.target.value));
  };

  React.useEffect(() => {
    /*setArr(_.map(cebelnjak.data, (o) => _.pick(o, ['id_cebelnjak', 'st_SI'])));*/
    dispatch(setSelectedCebelnjak(cebelnjak?.data[0]));
  }, [cebelnjak.data]);
  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        ml: { lg: `${drawerWidth}px` },
      }}
    >
      <Toolbar sx={{ justifyContent: 'center', p: 0 }}>
        {/*<IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onOpen}
          sx={{ mr: 2, display: { lg: "none" } }}
        >
          <MenuIcon />
    </IconButton>*/}
        <TextField
          label="Številka čebelnjaka"
          value={cebelnjak?.selected}
          onChange={handleChange}
          size="small"
          sx={{ width: 135, textAlign: 'center' }}
          select
        >
          {_.map(cebelnjak.data, (item) => {
            return (
              <MenuItem sx={{ justifyContent: 'center' }} key={item} value={item}>
                {item.st_SI}
              </MenuItem>
            );
          })}
          <MenuItem
            sx={{ justifyContent: 'center' }}
            key={'dodaj_panj'}
            value="dodaj_panj"
            onClick={() => {
              navigate('/new');
            }}
          >
            Dodaj (+)
          </MenuItem>
        </TextField>
        {/*<Avatar alt="Božidar Pezdevšek" src="/bp2.jpg" />*/}
      </Toolbar>
    </AppBar>
  );
}
