import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { deletePregled, getPregledi } from 'store/slices/preglediSlice';
import { useDispatch, useSelector } from 'react-redux';
import PreglediTableHead from './PreglediTableHead';
import _ from 'lodash';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function PreglediTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = React.useState(true);
  const pregledi = useSelector((state) => state.pregledi);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPregledi()).then(() => setLoading(false), console.log('Pregledi', pregledi));
  }, []);

  if (loading) {
    return <h1>Rendering..</h1>;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = _.map(pregledi, (n) => n.datum);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = _.indexOf(selected, name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = _.concat(newSelected, selected, name);
    } else if (selectedIndex === 0) {
      newSelected = _.concat(newSelected, _.slice(selected, 1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = _.concat(newSelected, _.slice(selected, 0, -1));
    } else if (selectedIndex > 0) {
      newSelected = _.concat(
        newSelected,
        _.slice(selected, 0, selectedIndex),
        _.slice(selected, selectedIndex + 1)
      );
    }

    setSelected(newSelected);

    dispatch(deletePregled(name));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (item) => {
    props.navigate(`/pregledi/${item.id}`);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 550 }} aria-labelledby="tableTitle">
            <PreglediTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={pregledi.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(pregledi, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((pregled, index) => {
                  const isSelected = selected.indexOf(pregled.datum) !== -1;
                  const labelId = `pregledi-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={pregled.datum}
                      selected={isSelected}
                      onClick={handleRowClick}
                    >
                      <TableCell
                        onClick={(event) => handleClick(event, pregled.id_pregled)}
                        padding="checkbox"
                      >
                        <Checkbox
                          color="primary"
                          checked={isSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{ minWidth: 160 }}
                        padding="none"
                      >
                        {pregled.datum}
                      </TableCell>

                      <TableCell align="right">{pregled.id_panj}</TableCell>
                      <TableCell align="right">
                        {_.isEqual(pregled.mirnost, 1) ? 'DA' : 'NE'}
                      </TableCell>
                      <TableCell align="right">{_.isEqual(pregled.moc, 1) ? 'DA' : 'NE'}</TableCell>
                      <TableCell align="right">
                        {_.isEqual(pregled.matica, 1) ? 'DA' : 'NE'}
                      </TableCell>
                      <TableCell align="right">
                        {_.isEqual(pregled.maticniki, 1) ? 'DA' : 'NE'}
                      </TableCell>
                      <TableCell align="right">
                        {_.isEqual(pregled.jajceca, 1) ? 'DA' : 'NE'}
                      </TableCell>
                      <TableCell align="right">
                        {_.isEqual(pregled.zerke, 1) ? 'DA' : 'NE'}
                      </TableCell>
                      <TableCell align="right">
                        {_.isEqual(pregled.pokrita_zalega, 1) ? 'DA' : 'NE'}
                      </TableCell>
                      <TableCell align="right">
                        {_.isEqual(pregled.zaloga_hrane, 1) ? 'DA' : 'NE'}
                      </TableCell>
                      <TableCell align="right">
                        {_.isEqual(pregled.prevesanje, 1) ? 'DA' : 'NE'}
                      </TableCell>
                      <TableCell align="right">
                        {_.isEqual(pregled.izrez_trotovine, 1) ? 'DA' : 'NE'}
                      </TableCell>
                      <TableCell align="right">
                        {_.isEqual(pregled.poapnela_zalega, 1) ? 'DA' : 'NE'}
                      </TableCell>
                      <TableCell align="right">
                        {_.isEqual(pregled.varoja, 1) ? 'DA' : 'NE'}
                      </TableCell>

                      <TableCell align="left">
                        {_.isNull(pregled.ugotovitve) ? '/' : pregled.ugotovitve}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={pregledi.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
