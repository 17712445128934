import React from 'react';
import { CheckBox } from '@mui/icons-material';
import {
  Avatar,
  Checkbox,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Box } from '@mui/system';

export default function Opravila() {
  const pregledi = useSelector((state) => state.pregledi);
  const [opravila, setOpravila] = React.useState([]);

  _.head([1, 10, 5]);
  //Rezultat: [1]

  React.useEffect(() => {
    const arr = _.map(pregledi, (o) => _.pick(o, ['datum', 'ugotovitve', 'id_panj']));
    setOpravila(_.reject(arr, ['ugotovitve', null]));
  }, []);

  return (
    <div>
      <Stack pb={2} direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontWeight="bold" variant="h5">
          Predvidena opravila
        </Typography>
      </Stack>
      <Stack spacing={2}>
        {opravila.map((data) => {
          const labelId = `checkbox-list-secondary-label-${data.opravilo}`;
          return (
            <ListItem
              sx={{
                overflow: 'hidden',
                backgroundColor: 'background.paper',
                borderRadius: 4,
              }}
              key={data.opravilo}
              disablePadding
            >
              <ListItemButton disableRipple>
                <ListItemText
                  id={labelId}
                  primary={data.ugotovitve}
                  secondary={
                    <Box mt="4px">
                      <Chip
                        sx={{ mr: 1 }}
                        color="success"
                        size="small"
                        label={`Panj ${data.id_panj}`}
                      />
                      <Chip color="warning" size="small" label={data.datum} />
                    </Box>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </Stack>
    </div>
  );
}
