import React from "react";
import { useTheme } from "@mui/material";
import { GlobalStyles } from "@mui/material";

export default function GlobalThemeStyles() {
  const theme = useTheme();
  return (
    <GlobalStyles
      styles={{
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        },
        html: {
          width: "100%",
          height: "100%",
        },

        body: {
          width: "100%",
          height: "100%",
        },
        "#root": {
          width: "100%",
          height: "100%",
        },
      }}
    />
  );
}
