import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import customAxios from '../../axios';

export const getHranjenja = createAsyncThunk('hranjenja/getHranjenja', async () => {
  const response = await customAxios.get('/hranjenja');
  const data = await response.data;
  return data;
});

export const addHranjenje = createAsyncThunk('hranjenja/addHranjenje', async (data) => {
  const response = await customAxios.post('/hranjenja/addHranjenje', data);
  return await response.data;
});

export const getHrana = createAsyncThunk('hranjenja/getHrana', async () => {
  const response = await customAxios.get('/hranjenja/getHrana');
  console.log(response);
  return await response.data;
});

const hranjenjaSlice = createSlice({
  name: '/hranjenja',
  initialState: {
    hranjenja: [],
    hrana: [],
  },
  reducers: {},
  extraReducers: {
    [getHranjenja.fulfilled]: (state, action) => {
      state.hranjenja = action.payload;
    },
    [addHranjenje.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.hranjenja = [...state.hranjenja, action.payload];
    },
    [getHrana.fulfilled]: (state, action) => {
      state.hrana = action.payload;
    },
  },
});

export default hranjenjaSlice.reducer;
