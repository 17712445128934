import React from 'react';
import { refresh } from 'store/slices/authSlice';

const { useSelector, useDispatch } = require('react-redux');
const { useLocation, Outlet, Navigate } = require('react-router-dom');

const RequireAuth = () => {
  const [loading, setLoading] = React.useState(true);
  const auth = useSelector((state) => state.auth);
  const checkToken = document.cookie.indexOf('ct') == 0;
  const location = useLocation();
  const dispatch = useDispatch();
  console.log('CheckToken', checkToken);

  React.useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        dispatch(refresh());
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setLoading(false);
      }
    };

    !auth?.accessToken && checkToken ? verifyRefreshToken() : setLoading(false);
    return () => (isMounted = false);
  }, []);

  return <>{!checkToken ? <Outlet /> : loading ? <p>Loading...</p> : <Outlet />}</>;
};

export default RequireAuth;
