import React from "react";
import { Typography, Stack, Button } from "@mui/material";
import ZdravljenjaTable from "./ZdravljenjaTable";
import { Link } from "react-router-dom";

export default function Zdravljenja() {
  return (
    <div>
      <Stack
        pb={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography fontWeight="bold" variant="h5">
          Zdravljenja
        </Typography>
        <Button
          component={Link}
          to="/zdravljenja/new"
          variant="contained"
          sx={{ borderRadius: 8, textTransform: "none" }}
        >
          Dodaj zdravljenja
        </Button>
      </Stack>
      <ZdravljenjaTable />
    </div>
  );
}
