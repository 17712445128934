import axios from 'axios';
import customAxios from '../../axios';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { dispatch, getState }) => {
    const response = await axios.post(
      'http://api.cebelarstvo-pezdevsek.si/api/v1/users/login',
      { email, password },
      { headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials': true } }
    );
    const data = await response.data;
    return data;
  }
);
export const refresh = createAsyncThunk('auth/refresh', async (_, { dispatch, getState }) => {
  const response = await customAxios.post('/users/refresh');
  const data = await response.data;
  return data;
});

export const logout = createAsyncThunk('auth/logout', async (_, { dispatch, getState }) => {
  const { auth } = getState();
  const response = await customAxios.post('/users/refresh', auth.token);
  const data = await response.data;
  return data;
});

const authSlice = createSlice({
  name: 'auth',
  initialState: { id_cebelar: null, accessToken: null },
  reducers: {},
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      const { id_cebelar, accessToken } = action.payload;
      state.id_cebelar = id_cebelar;
      state.accessToken = accessToken;
    },
    [refresh.fulfilled]: (state, action) => {
      const { id_cebelar, accessToken } = action.payload;
      state.id_cebelar = id_cebelar;
      state.accessToken = accessToken;
    },
    [logout.fulfilled]: (state, action) => {},
  },
});

export default authSlice.reducer;
