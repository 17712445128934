import { Box, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { getAuth } from 'store/slices/authSlice';
import { getUserCebelnjakInfo } from 'store/slices/cebelnjakSlice';
import { getHrana } from 'store/slices/hranjenjaSlice';
import { getBarve, getPanji, getPanjiVrste } from 'store/slices/panjSlice';
import { getPregledi } from 'store/slices/preglediSlice';
import { getBolezni } from 'store/slices/zdravljenjaSlice';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

const DashboardWrapper = styled(Box)({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const DashboardContent = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  flexGrow: 1,
  minHeight: '100%',
  paddingTop: 30,
  backgroundColor: '#f6f7f9',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

function getAllData(dispatch, id_cebelar) {
  dispatch(getUserCebelnjakInfo(id_cebelar));
  dispatch(getPregledi());
  dispatch(getHrana());
  dispatch(getBolezni());
  dispatch(getPanji());
  dispatch(getPanjiVrste());
  dispatch(getBarve());
}
export default function Dashboard() {
  const auth = useSelector((state) => state.auth);
  const id_cebelar = auth?.id_cebelar;
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getUserCebelnjakInfo(id_cebelar));
    dispatch(getPregledi());
    dispatch(getHrana());
    dispatch(getBolezni());
    dispatch(getPanji());
    dispatch(getPanjiVrste());
    dispatch(getBarve());
  }, []);

  return (
    <DashboardWrapper>
      {/*<DashboardSidebar open={open} onClose={() => setOpen(false)} />*/}
      <DashboardNavbar onOpen={() => setOpen(true)} />
      <DashboardContent>
        <Toolbar />
        <Outlet />
      </DashboardContent>
    </DashboardWrapper>
  );
}
