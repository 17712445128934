import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import customAxios from '../../axios';

export const getZdravljenja = createAsyncThunk('zdravljenja/getZdravljenja', async () => {
  const response = await customAxios.get('/zdravljenja');
  const data = response.data;
  return data;
});

export const getBolezni = createAsyncThunk('zdravljenja/getBolezni', async () => {
  const response = await customAxios.get('/zdravljenja/getBolezni');
  const data = response.data;
  console.log(data);
  return data;
});

export const addZdravljenje = createAsyncThunk('zdravljenja/addZdravljenje', async (data) => {
  console.log(data);
  const response = await customAxios.post('/zdravljenja/addZdravljenje', data);
  return await response.data;
});

const zdravljenjaSlice = createSlice({
  name: '/zdravljenja',
  initialState: {
    zdravljenja: [],
    bolezni: [],
  },
  reducers: {},
  extraReducers: {
    [getZdravljenja.fulfilled]: (state, action) => {
      state.zdravljenja = action.payload;
    },
    [addZdravljenje.fulfilled]: (state, action) => {
      state.zdravljenja = [...state.zdravljenja, action.payload];
    },
    [getBolezni.fulfilled]: (state, action) => {
      state.bolezni = action.payload;
    },
  },
});

export default zdravljenjaSlice.reducer;
