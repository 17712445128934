import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { Avatar, useMediaQuery, useTheme } from "@mui/material";
import { drawerConfig } from "configs/drawerConfig";
import { useLocation } from "react-router-dom";
import { NavItem } from "./NavItem";
import { styled } from "@mui/material/styles";
const drawerWidth = 275;

const Wrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: drawerWidth,
  },
}));

function DashboardSidebar({ open, onClose }) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  React.useEffect(() => {
    if (open) onClose();
  }, [pathname]);
  //console.log(location);
  const drawer = (
    <div>
      <Box
        m={2}
        p={3}
        borderRadius={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor="rgba(255,255,255, 0.08)"
      >
        <Avatar
          alt="Božidar Pezdevšek"
          src="/bp2.jpg"
          sx={{ width: 84, height: 84, mb: 1 }}
        />
        <Typography variant="h7" fontWeight="bold">
          Božidar Pezdevšek
        </Typography>
      </Box>
      <List
        sx={{
          m: 2,
          p: 1,
          borderRadius: 2,
          backgroundColor: "rgba(255,255,255, 0.03)",
        }}
      >
        {drawerConfig.map((item) => (
          <NavItem
            key={item.title}
            title={item.title}
            path={item.path}
            icon={item.icon}
          />
        ))}
      </List>
    </div>
  );

  return (
    <>
      <CssBaseline />
      <Wrapper>
        {isMobile && (
          <Drawer
            open={open}
            onClose={onClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            PaperProps={{
              sx: {
                backgroundColor: "#252f3e",
                color: "#FFFFFF",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        )}

        {!isMobile && (
          <Drawer
            variant="permanent"
            PaperProps={{
              sx: {
                backgroundColor: "#252f3e",
                color: "#FFFFFF",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        )}
      </Wrapper>
    </>
  );
}

DashboardSidebar.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DashboardSidebar;
