import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Paper } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _, { replace } from 'lodash';
import { useDispatch } from 'react-redux';
import { login } from 'store/slices/authSlice';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('E-poštni naslov mora biti pravilen.')
    .required('Vnesite uporabniško e-poštni naslov.'),
  password: yup
    .string()
    .required('Vnesite geslo.')
    .min(6, 'Vaše geslo mora biti dolgo vsaj 6 znakov.'),
});

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const { reset, watch, control, onChange, formState, handleSubmit } = methods;
  const { errors, dirtyFields, isValid } = formState;

  function onSubmit({ email, password }) {
    console.log('onsubmitting');
    console.log(email, password);
    dispatch(login({ email, password })).then(() => navigate('/', { replace: true }));
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      component="main"
      sx={{ backgroundColor: 'primary.main' }}
    >
      <Container maxWidth="xs" sx={{ paddingTop: 10 }}>
        <CssBaseline />
        <Paper sx={{ borderRadius: 4 }}>
          <Box
            padding={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box pb={1}>
              <img src="/beehive.png" />
            </Box>
            <Typography fontWeight="bold" variant="h5">
              Prijava
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1, width: '100%' }}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="E-poštni naslov"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    sx={{ marginTop: 1 }}
                    {...field}
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    required
                    fullWidth
                    name="password"
                    label="Geslo"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                )}
              />

              <Button
                size="large"
                type="submit"
                fullWidth
                variant="contained"
                disabled={_.isEmpty(dirtyFields) || !isValid}
                sx={{ mt: 2, mb: 2 }}
              >
                Prijava
              </Button>
            </form>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}
