import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';

import {
  Box,
  Button,
  Icon,
  Menu,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { addPanj } from 'store/slices/panjSlice';

const schema = yup.object().shape({
  naziv: yup.string().required('Vnesite naziv panja.'),
  id_barva: yup.number().required('Vnesite barvo panja.'),
  id_vrsta: yup.number().required('Vnesite vrsto panja.'),
  etaze: yup.number().required('Vnesite koliko etaž ima panj.'),
});

export default function Panj(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const id_cebelnjak = useSelector((state) => state.cebelnjak.selected.id_cebelnjak);
  const panji = useSelector((state) => state.panji.panji);
  const vrste_panja = useSelector((state) => state.panji.vrste);
  const barve = useSelector((state) => state.panji.barve);
  console.log('Vrste', vrste_panja);

  const methods = useForm({
    mode: 'onChange',
    defaultValue: {},
    resolver: yupResolver(schema),
  });

  const { reset, watch, control, onChange, formState, handleSubmit } = methods;
  const onSubmit = (data) => {
    //data.kolicina = parseInt(data.kolicina);
    console.log('idcebelnjak je', id_cebelnjak);
    data.id_cebelnjak = id_cebelnjak;
    console.log('data pa je: ', data);
    dispatch(addPanj(data));
  };

  return (
    <div>
      <Box textAlign="center" mb={4}>
        <Icon onClick={() => navigate(-1)}>arrow_back_rounded</Icon>
        <Typography variant="h5" fontWeight="bold">
          Nov panj
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1.5}>
          <Controller
            name="naziv"
            control={control}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <TextField
                  sx={{ backgroundColor: 'background.paper' }}
                  {...field}
                  required
                  fullWidth
                  id="naziv"
                  label="Naziv panja"
                  type="text"
                  variant="outlined"
                />
              </>
            )}
          />

          <Controller
            name="id_vrsta"
            control={control}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <TextField
                  sx={{ backgroundColor: 'background.paper' }}
                  {...field}
                  required
                  fullWidth
                  select
                  id="id_vrsta"
                  label="Vrsta"
                  type="text"
                  variant="outlined"
                >
                  {_.map(vrste_panja, (item) => {
                    return (
                      <MenuItem key={item.vrsta} value={item.id_vrsta}>
                        {item.vrsta}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </>
            )}
          />

          <Controller
            name="id_barva"
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  sx={{ backgroundColor: 'background.paper' }}
                  {...field}
                  required
                  fullWidth
                  select
                  id="id_barva"
                  label="Barva"
                  type="text"
                  variant="outlined"
                >
                  {_.map(barve, (item) => {
                    return (
                      <MenuItem key={item.barva} value={item.id_barva}>
                        {item.barva}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </>
            )}
          />

          <Controller
            name="etaze"
            control={control}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <TextField
                  sx={{ backgroundColor: 'background.paper' }}
                  {...field}
                  required
                  fullWidth
                  id="etaze"
                  label="Število satov"
                  type="number"
                  variant="outlined"
                />
              </>
            )}
          />
        </Stack>
        <Button
          sx={{ borderRadius: 8, my: 3, p: 2 }}
          size="large"
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => navigate(-1)}
          fullWidth
        >
          Vnesi Panj
        </Button>
      </form>
    </div>
  );
}
