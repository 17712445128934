import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Icon,
  Menu,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { addCebelnjak } from 'store/slices/cebelnjakSlice';

const schema = yup.object().shape({
  st_SI: yup.number().required('Vnesite registrsko oznako čebelnjaka .'),
  st_KO: yup.number().required('Vnesite številko katastrske občine.'),
  st_parcele: yup.number().required('Vnesite številko parcele.'),
});

export default function CebelnjakNew(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const cebelnjak = useSelector((state) => state.cebelnjak.data);
  const auth = useSelector((state) => state.auth);

  const methods = useForm({
    mode: 'onChange',
    defaultValue: {},
    resolver: yupResolver(schema),
  });

  const { reset, watch, control, onChange, formState, handleSubmit } = methods;
  const { errors, dirtyFields, isValid } = formState;

  const onSubmit = (data) => {
    //data.kolicina = parseInt(data.kolicina);
    /*_.assign(data, { id_cebelar });*/
    console.log('AUTHHHH', auth.id_cebelar);
    dispatch(addCebelnjak({ data, id_cebelar: auth.id_cebelar }));
    navigate('/', { replace: true });
  };

  return (
    <div>
      <Box textAlign="center" mb={4}>
        <Icon onClick={() => navigate(-1)}>arrow_back_rounded</Icon>
        <Typography variant="h5" fontWeight="bold">
          Registracija čebelnjaka
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1.5}>
          <Controller
            name="st_SI"
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  sx={{ backgroundColor: 'background.paper' }}
                  {...field}
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  required
                  fullWidth
                  id="st_SI"
                  label="Registrska oznaka čebelnjaka"
                  type="number"
                  variant="outlined"
                />
              </>
            )}
          />

          <Controller
            name="st_KO"
            control={control}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <TextField
                  sx={{ backgroundColor: 'background.paper' }}
                  {...field}
                  error={!!errors.st_KO}
                  helperText={errors?.st_KO?.message}
                  required
                  fullWidth
                  id="st_KO"
                  label="Številka katastrske občine"
                  type="number"
                  variant="outlined"
                />
              </>
            )}
          />

          <Controller
            name="st_parcele"
            control={control}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <TextField
                  sx={{ backgroundColor: 'background.paper' }}
                  {...field}
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  required
                  fullWidth
                  id="st_parcele"
                  label="Številka parcele"
                  type="number"
                  variant="outlined"
                />
              </>
            )}
          />
        </Stack>
        <Button
          sx={{ borderRadius: 8, my: 3, p: 2 }}
          size="large"
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
        >
          Vnesi čebelnjak
        </Button>
      </form>
    </div>
  );
}
