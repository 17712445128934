import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Icon, MenuItem, Stack, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import { addZdravljenje } from 'store/slices/zdravljenjaSlice';
const schema = yup.object().shape({
  id_panj: yup.number().required('Vnesite potrebne podatke'),
});

export default function Zdravljenje(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const panji = useSelector((state) => state.panji.panji);
  const bolezni = useSelector((state) => state.zdravljenja.bolezni);

  const methods = useForm({
    mode: 'onChange',
    defaultValue: {},
    resolver: yupResolver(schema),
  });
  const { reset, watch, control, onChange, formState, handleSubmit } = methods;

  const onSubmit = (data) => {
    console.log('Adding zdravljenje.', data);
    dispatch(addZdravljenje(data));
  };

  return (
    <div>
      <Box textAlign="center" mb={4}>
        <Icon onClick={() => navigate(-1)}>arrow_back_rounded</Icon>
        <Typography variant="h5" fontWeight="bold">
          Novo zdravljenje
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1.5}>
          <Controller
            name="id_panj"
            control={control}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <TextField
                  sx={{ backgroundColor: 'background.paper' }}
                  {...field}
                  required
                  fullWidth
                  select
                  id="id_panj"
                  label="Panj"
                  type="number"
                  variant="outlined"
                >
                  {_.map(panji, (item) => {
                    return (
                      <MenuItem key={item.id_panj + item.id_barva} value={item.id_panj}>
                        {item.id_panj}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </>
            )}
          />

          <Controller
            name="id_bolezni"
            control={control}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <TextField
                  sx={{ backgroundColor: 'background.paper' }}
                  {...field}
                  required
                  fullWidth
                  select
                  id="id_bolezen"
                  label="Bolezen"
                  type="number"
                  variant="outlined"
                >
                  {_.map(bolezni, (item) => {
                    return (
                      <MenuItem key={item.bolezen} value={item.id_bolezni}>
                        {item.bolezen}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </>
            )}
          />

          <Controller
            name="opombe"
            control={control}
            render={({ field }) => (
              <>
                {console.log(field.value)}
                <TextField
                  sx={{ backgroundColor: 'background.paper' }}
                  {...field}
                  required
                  fullWidth
                  id="opombe"
                  label="Opombe"
                  type="text"
                  variant="outlined"
                />
              </>
            )}
          />
        </Stack>
        <Button
          sx={{ borderRadius: 8, my: 3, p: 2 }}
          size="large"
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          onClick={() => navigate(-1)}
        >
          Vnesi zdravljenje
        </Button>
      </form>
    </div>
  );
}
